import { AnswerResult } from './questionnaireManager';

export interface SGResult {
  question: string;
  answer: number;
}

// answers are typically (Almost Never:1, Sometimes:2, Almost Always: 3)

const answers: AnswerResult[] = [
  {
    text: 'Never',
    value: 1,
    colour: '#EBCF37',
  },
  {
    text: 'Rarely',
    value: 2,
    colour: '#7DB474',
  },
  {
    text: 'Sometimes',
    value: 3,
    colour: '#74b9ff',
  },
  {
    text: 'Often',
    value: 4,
    colour: '#a29bfe',
  },
  {
    text: 'Always',
    value: 5,
    colour: '#ff7675',
  },
];

const questions: Record<number, SGResult> = {
  1: { question: 'I like organizing services and events.', answer: 0 },
  2: { question: 'I am interested in starting new churches.', answer: 0 },
  3: { question: 'I enjoy working with my hands.', answer: 0 },
  4: { question: 'I can tell when someone is insincere.', answer: 0 },
  5: { question: "I pray daily for people who don't know Jesus.", answer: 0 },
  6: {
    question: 'Encouraging others is a high priority in my life.',
    answer: 0,
  },
  7: { question: 'I trust God to provide for my daily needs.', answer: 0 },
  8: {
    question:
      'I am passionate about financially investing in the Kingdom of God.',
    answer: 0,
  },
  9: { question: 'I look for opportunities to pray for the sick.', answer: 0 },
  10: {
    question: 'I enjoy doing little things that others typically do not enjoy.',
    answer: 0,
  },
  11: { question: 'I often have people over to my house.', answer: 0 },
  12: {
    question: 'I enjoy spending hours in prayer for other people.',
    answer: 0,
  },
  13: { question: 'Education is very important to me.', answer: 0 },
  14: { question: 'I tend to motivate others to get involved.', answer: 0 },
  15: { question: 'I hurt when I see others hurting.', answer: 0 },
  16: {
    question: 'I believe God will use me to enact His miracles.',
    answer: 0,
  },
  17: {
    question:
      'I enjoy sharing the Gospel with other people groups and nationalities.',
    answer: 0,
  },
  18: {
    question:
      "I've devoted considerable time to mastering my voice and/or musical instrument.",
    answer: 0,
  },
  19: {
    question: 'Caring for the hurting is one of my highest priorities.',
    answer: 0,
  },
  20: { question: 'I get frustrated when people knowingly sin.', answer: 0 },
  21: { question: 'I enjoy serving behind the scenes.', answer: 0 },
  22: { question: 'I like creating outlines of the Bible.', answer: 0 },
  23: {
    question:
      'God has used me to interpret what someone speaking in tongues is saying.',
    answer: 0,
  },
  24: {
    question:
      'I enjoy the book of Proverbs more than any other book in the Bible.',
    answer: 0,
  },
  25: { question: 'I am passionate about managing details.', answer: 0 },
  26: { question: 'I like to help start new ministry projects.', answer: 0 },
  27: { question: 'I consider myself a craftsman or craftswoman.', answer: 0 },
  28: {
    question: 'I sense when situations are spiritually unhealthy.',
    answer: 0,
  },
  29: {
    question:
      "I am greatly motivated by seeing people who don't know God be saved.",
    answer: 0,
  },
  30: { question: 'I come across as loving and caring.', answer: 0 },
  31: {
    question:
      'Asking God for a list of seemingly impossible things is exciting to me.',
    answer: 0,
  },
  32: { question: 'I find ways to give offerings above my tithe.', answer: 0 },
  33: {
    question: 'I believe miraculous healing is possible and still happens.',
    answer: 0,
  },
  34: {
    question: 'Helping others is one of my greatest motivations.',
    answer: 0,
  },
  35: {
    question: 'Creating a warm and welcoming environment is important to me.',
    answer: 0,
  },
  36: {
    question: 'I am burdened to pray for situations affecting the world.',
    answer: 0,
  },
  37: {
    question: 'People come to me to learn more about God and the Bible.',
    answer: 0,
  },
  38: { question: 'I prefer to take the lead whenever possible.', answer: 0 },
  39: { question: "I'm very sensitive to sad stories.", answer: 0 },
  40: { question: "Miracles often happen when I'm nearby.", answer: 0 },
  41: {
    question:
      'The idea of living in another country to benefit the Gospel is exciting to me.',
    answer: 0,
  },
  42: { question: 'I desire to serve the church through worship.', answer: 0 },
  43: {
    question: 'I enjoy connecting, caring for, and coaching others.',
    answer: 0,
  },
  44: {
    question:
      'Confronting someone about a sin in their life is important to me.',
    answer: 0,
  },
  45: {
    question: 'It bothers me when people sit around and do nothing.',
    answer: 0,
  },
  46: {
    question: 'I share Biblical truth with others to help them grow.',
    answer: 0,
  },
  47: { question: 'I pray in tongues daily.', answer: 0 },
  48: {
    question: 'When I study Scripture, I receive unique insights from God.',
    answer: 0,
  },
  49: {
    question: 'Creating a task list is easy and enjoyable for me.',
    answer: 0,
  },
  50: {
    question: 'I am attracted to ministries that start new churches.',
    answer: 0,
  },
  51: {
    question: 'Building something with my hands is very satisfying to me.',
    answer: 0,
  },
  52: { question: 'I can pinpoint issues or problems quickly.', answer: 0 },
  53: {
    question:
      'Sharing the Gospel with someone I do not know is exciting and natural for me.',
    answer: 0,
  },
  54: { question: 'I look for ways to encourage other people.', answer: 0 },
  55: {
    question: 'I trust that God has my back in every situation.',
    answer: 0,
  },
  56: {
    question: 'I want to make more money so that I can give more.',
    answer: 0,
  },
  57: {
    question: 'God has used me to bring healing to those who are sick.',
    answer: 0,
  },
  58: {
    question: 'Being a part of the process is fulfilling to me.',
    answer: 0,
  },
  59: { question: 'I tend to make total strangers feel at home.', answer: 0 },
  60: { question: 'People often ask me to pray for them.', answer: 0 },
  61: {
    question:
      'I enjoy knowing Biblical details and helping others understand them, too.',
    answer: 0,
  },
  62: {
    question: 'I delegate responsibilities to accomplish tasks.',
    answer: 0,
  },
  63: { question: 'I am motivated to help people in need.', answer: 0 },
  64: {
    question: "I have a constant hunger to see God's miraculous power.",
    answer: 0,
  },
  65: {
    question: 'I focus a lot on reaching the world for Christ.',
    answer: 0,
  },
  66: {
    question:
      'I gain my deepest satisfaction through leading others in vocal or instrumental worship.',
    answer: 0,
  },
  67: {
    question: 'I enjoy helping people who are going through a difficult time.',
    answer: 0,
  },
  68: {
    question: "I enjoy hearing passionate and clear preaching of God's Word.",
    answer: 0,
  },
  69: {
    question: 'I like to do small things that others overlook.',
    answer: 0,
  },
  70: {
    question:
      'I prefer to teach and study the Bible topically rather than verse by verse.',
    answer: 0,
  },
  71: {
    question: 'Praying in tongues is encouraging and important to me.',
    answer: 0,
  },
  72: {
    question: 'When faced with difficulty, I tend to make wise decisions.',
    answer: 0,
  },
};

export interface AnswerDetail {
  key: string;
  title: string;
  description: string;
  verses: string;
  total: number;
  mapping: number[];
}

const answerDetails: AnswerDetail[] = [
  {
    key: 'A',
    title: 'Administration',
    description:
      'The gift of administration is the divine strength or ability to organize multiple tasks and groups of people to accomplish these tasks.',
    verses: 'Luke 14:28-30; Acts 6:1-7; 1 Corinthians 12:28',
    total: 0,
    mapping: [1, 25, 49],
  },
  {
    key: 'B',
    title: 'Apostleship',
    description:
      'The gift of apostleship is the divine strength or ability to pioneer new churches and ministries through planting, overseeing, and training.',
    verses:
      'Acts 15:22-35; 1 Corinthians 12:28; 2 Corinthians 12:12; Galatians 2:7-10; Ephesians 4:11-14',
    total: 0,
    mapping: [2, 26, 50],
  },
  {
    key: 'C',
    title: 'Craftsmanship',
    description:
      'The gift of craftsmanship is the divine strength or ability to plan, build, and work with your hands in construction environments to accomplish multiple ministry applications.',
    verses: 'Exodus 30:22, 31:3-11; 2 Chronicles 34:9-13; Acts 18:2-3',
    total: 0,
    mapping: [3, 27, 51],
  },
  {
    key: 'D',
    title: 'Discernment',
    description:
      'The gift of discernment is the divine strength or ability to spiritually identify falsehood and to distinguish between right and wrong motives and situations.',
    verses:
      'Matthew 16:21-23; Acts 5:1-11, 16:16-18; 1 Corinthians 12:10; 1 John 4:1-6',
    total: 0,
    mapping: [4, 28, 52],
  },
  {
    key: 'E',
    title: 'Evangelism',
    description:
      'The gift of evangelism is the divine strength or ability to help non-Christians take the necessary steps to becoming Christ followers.',
    verses: 'Acts 8:5-6, 8:26-40, 14:21, 21:8; Ephesians 4:11-14',
    total: 0,
    mapping: [5, 29, 53],
  },
  {
    key: 'F',
    title: 'Exhortation',
    description:
      'The gift of exhortation is the divine strength or ability to encourage others through the written or spoken word and Biblical truth.',
    verses: 'Acts 14:22; Romans 12:8; 1 Timothy 4:13; Hebrews 10:24-25',
    total: 0,
    mapping: [6, 30, 54],
  },
  {
    key: 'G',
    title: 'Faith',
    description:
      'The gift of faith is the divine strength or ability to believe in God for unseen supernatural results in every arena of life.',
    verses: 'Acts 11:22-24; Romans 4:18-21; 1 Corinthians 12:9; Hebrews 11',
    total: 0,
    mapping: [7, 31, 55],
  },
  {
    key: 'H',
    title: 'Giving',
    description:
      'The gift of giving is the divine strength or ability to produce wealth and to give by tithes and offerings for the purpose of advancing the Kingdom of God on earth.',
    verses: 'Mark 12:41-44; Romans 12:8; 2 Corinthians 8:1-7, 9:2-7',
    total: 0,
    mapping: [8, 32, 56],
  },
  {
    key: 'I',
    title: 'Healing',
    description:
      'The gift of healing is the divine strength or ability to act as an intermediary in faith, prayer, and by the laying-on of hands for the healing of physical and mental illnesses.',
    verses: 'Acts 3:1-10, 9:32-35, 28:7-10; 1 Corinthians 12:9, 28',
    total: 0,
    mapping: [9, 33, 57],
  },
  {
    key: 'J',
    title: 'Helps',
    description:
      'The gift of helps is the divine strength or ability to work in a supportive role for the accomplishment of tasks in Christian ministry.',
    verses: 'Mark 15:40-41; Acts 9:36; Romans 16:1-2; 1 Corinthians 12:28',
    total: 0,
    mapping: [10, 34, 58],
  },
  {
    key: 'K',
    title: 'Hospitality',
    description:
      'The gift of hospitality is the divine strength or ability to create warm, welcoming environments for others in places such as your home, office, or church.',
    verses: 'Acts 16:14-15; Romans 12:13, 16:23; Hebrews 13:1-2; 1 Peter 4:9',
    total: 0,
    mapping: [11, 35, 59],
  },
  {
    key: 'L',
    title: 'Intercession',
    description:
      'The gift of intercession is the divine strength or ability to stand in the gap in prayer for someone, something, or someplace believing for profound results.',
    verses: 'Hebrews 7:25; Colossians 1:9-12, 4:12-13; James 5:14-16',
    total: 0,
    mapping: [12, 36, 60],
  },
  {
    key: 'M',
    title: 'Knowledge',
    description:
      'The gift of knowledge is the divine strength or ability to understand and to bring clarity to situations and circumstances often accompanied by a word from God.',
    verses: 'Acts 5:1-11; 1 Corinthians 12:8; Colossians 2:2-3',
    total: 0,
    mapping: [13, 37, 61],
  },
  {
    key: 'N',
    title: 'Leadership',
    description:
      'The gift of leadership is the divine strength or ability to influence people at their level while directing and focusing them on the big picture, vision, or idea.',
    verses: 'Romans 12:8; 1 Timothy 3:1-13, 5:17; Hebrews 13:17',
    total: 0,
    mapping: [14, 38, 62],
  },
  {
    key: 'O',
    title: 'Mercy',
    description:
      'The gift of mercy is the divine strength or ability to feel empathy and to care for those who are hurting in any way.',
    verses: 'Matthew 9:35-36; Mark 9:41; Romans 12:8; 1 Thessalonians 5:14',
    total: 0,
    mapping: [15, 39, 63],
  },
  {
    key: 'P',
    title: 'Miracles',
    description:
      'The gift of miracles is the divine strength or ability to alter the natural outcomes of life in a supernatural way through prayer, faith, and divine direction.',
    verses:
      'Acts 9:36-42, 19:11-12, 20:7-12; Romans 15:18-19; 1 Corinthians 12:10, 28',
    total: 0,
    mapping: [16, 40, 64],
  },
  {
    key: 'Q',
    title: 'Missionary',
    description:
      'The missionary gift is the divine strength or ability to reach others outside of your culture and nationality, while in most cases living in that culture or nation.',
    verses: 'Acts 8:4, 13:2-3, 22:21; Romans 10:15',
    total: 0,
    mapping: [17, 41, 65],
  },
  {
    key: 'R',
    title: 'Music/Worship',
    description:
      'The gift of music/worship is the divine strength or ability to sing, dance, or play an instrument primarily for the purpose of helping others worship God.',
    verses:
      'Deuteronomy 31:22; 1 Samuel 16:16; 1 Chronicles 16:41-42; 2 Chronicles 5:12-13, 34:12; Psalm 150',
    total: 0,
    mapping: [18, 42, 66],
  },
  {
    key: 'S',
    title: 'Pastor/Shepherd',
    description:
      'The gift of pastor/shepherd is the divine strength or ability to care for the personal needs of others by nurturing and mending life issues.',
    verses: 'John 10:1-18; Ephesians 4:11-14; 1 Timothy 3:1-7; 1 Peter 5:1-3',
    total: 0,
    mapping: [19, 43, 67],
  },
  {
    key: 'T',
    title: 'Prophecy',
    description:
      'The gift of prophecy is the divine strength or ability to boldly speak and bring clarity to scriptural and doctrinal truth, in some cases foretelling God’s plan.',
    verses:
      'Acts 2:37-40, 7:51-53, 26:24-29; 1 Corinthians 14:1-4; 1 Thessalonians 1:5',
    total: 0,
    mapping: [20, 44, 68],
  },
  {
    key: 'U',
    title: 'Service',
    description:
      'The gift of serving is the divine strength or ability to do small or great tasks in working for the overall good of the body of Christ.',
    verses:
      'Acts 6:1-7; Romans 12:7; Galatians 6:10; 1 Timothy 1:16-18; Titus 3:14',
    total: 0,
    mapping: [21, 45, 69],
  },
  {
    key: 'V',
    title: 'Teaching',
    description:
      'The gift of teaching is the divine strength or ability to study and learn from the Scriptures primarily to bring understanding and growth to other Christians.',
    verses: 'Acts 18:24-28, 20:20-21; 1 Corinthians 12:28; Ephesians 4:11-14',
    total: 0,
    mapping: [22, 46, 70],
  },
  {
    key: 'W',
    title: 'Tongues (and Interpretation)',
    description:
      'The gift of tongues is the divine strength or ability to pray in a heavenly language to encourage your spirit and to commune with God. The gift of tongues is often accompanied by interpretation and should be used appropriately.',
    verses: 'Acts 2:1-13; 1 Corinthians 12:10, 14:1-14',
    total: 0,
    mapping: [23, 47, 71],
  },
  {
    key: 'X',
    title: 'Wisdom',
    description:
      'The gift of wisdom is the divine strength or ability to apply the truths of Scripture in a practical way, producing a fruitful outcome and the character of Jesus Christ.',
    verses: 'Acts 6:3,10; 1 Corinthians 2:6-13, 12:8',
    total: 0,
    mapping: [24, 48, 72],
  },
];
export default {
  answers,
  questions,
  answerDetails,
};
