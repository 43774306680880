import { Button, Divider, Fade, Typography, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Layout } from '../../components/GrowthTrack';

import QuestionnaireManager, {
  IQuestionnaireManager,
  QuestionResult,
  QuestionResults,
} from '../../globals/infra/questionnaireManager';

const limit = false;

const Assessment = () => {
  const theme = useTheme();

  const [question, setQuestion] = React.useState<QuestionResult>(null);
  const [questionResults, setQuestionResults] = React.useState<QuestionResults>(
    null
  );

  const [fade, setFade] = React.useState(false);

  const _questions = React.useRef<IQuestionnaireManager>(null);

  const [started, setStarted] = React.useState(false);
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    if (!_questions.current) _questions.current = new QuestionnaireManager();
  }, []);

  const handleStart = () => {
    setStarted(true);

    handleNextQuestion();

    setTimeout(() => setFade(true), 250);
  };

  const handleSubmitQuestion = (question: string, value: number) => () => {
    _questions.current.answerQuestion(question, value);

    if (!_questions.current.isDone()) handleNextQuestion();
    else {
      setDone(true);

      const result = _questions.current.getResults(limit);

      console.log(result);
      setQuestionResults(result);
    }
  };

  const handleNextQuestion = () => {
    const q = _questions.current.getNextQuestion();

    setFade(false);

    setTimeout(() => setQuestion(q), 250);
    setTimeout(() => setFade(true), 300);
  };

  return (
    <Layout>
      <div style={{ padding: 16 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 24,
          }}
        >
          <img
            height="128px"
            src="/images/icon.png"
            alt="Lifehouse Church Logo"
          />
        </div>
        {!started && (
          <>
            <Typography
              align="center"
              color="primary"
              variant="h4"
              gutterBottom
            >
              Discovering Your Gifts & Personality
            </Typography>
            <Typography
              align="center"
              variant="subtitle1"
              style={{ maxWidth: 500, margin: '0 auto 64px auto' }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum
              at tempor commodo ullamcorper a lacus. Justo nec ultrices dui
              sapien eget mi proin. Eros in cursus turpis massa tincidunt dui.
            </Typography>

            <Button
              disableElevation
              variant="contained"
              color="primary"
              style={{ display: 'flex', margin: '0 auto' }}
              onClick={handleStart}
            >
              Start Assessment
            </Button>
          </>
        )}
        {started && !done && (
          <Fade in={fade} appear enter exit>
            <div>
              {question && (
                <>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ marginBottom: 16 }}
                  >
                    Question {question.questionNumber} of {question.total}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ marginBottom: 32 }}
                  >
                    {question.question}
                  </Typography>
                  {question.answers.map((answer) => (
                    <Button
                      key={answer.text}
                      disableElevation
                      variant="contained"
                      style={{
                        display: 'flex',
                        margin: '0 auto',
                        width: 160,
                        marginBottom: 8,
                        backgroundColor: answer.colour,
                        color: theme.palette.getContrastText(answer.colour),
                      }}
                      onClick={handleSubmitQuestion(
                        question.question,
                        answer.value
                      )}
                    >
                      {answer.text}
                    </Button>
                  ))}
                </>
              )}
            </div>
          </Fade>
        )}
        {done && (
          <>
            <Typography
              align="center"
              variant="h4"
              style={{ marginBottom: 16 }}
            >
              DISC Profile
            </Typography>
            {questionResults.discProfile.map((dp, idx) => {
              const [first, second] = questionResults.discProfile;

              const personalityType = `${first.type
                .charAt(0)
                .toUpperCase()}/${second.type.charAt(0).toUpperCase()}`;

              return (
                <React.Fragment key={dp.type}>
                  <div style={{ marginBottom: 16 }}>
                    {idx === 0 && (
                      <>
                        <Typography
                          style={{ marginBottom: 12 }}
                          align="center"
                          variant="h6"
                        >
                          Your personality type is <b>"{personalityType}"</b>
                        </Typography>
                        <Typography variant="h6">
                          {limit
                            ? `Dominant as `
                            : `[${first.total}] - Dominant as `}
                          <Typography
                            color="primary"
                            variant="h6"
                            component="span"
                          >
                            "{first.type.charAt(0).toUpperCase()}"
                          </Typography>
                          :
                        </Typography>
                        <Typography style={{ marginBottom: 8 }} variant="body1">
                          {dp.description}
                        </Typography>
                        <ul>
                          {dp.points.map((point) => (
                            <li>{point}</li>
                          ))}
                        </ul>
                      </>
                    )}
                    {idx === 1 && (
                      <Typography variant="h6">
                        {limit
                          ? `Personality Type `
                          : `[${second.total}] - Personality Type `}
                        <Typography
                          color="primary"
                          variant="h6"
                          component="span"
                        >
                          "{personalityType}"
                        </Typography>
                        :
                      </Typography>
                    )}
                    {dp.trait && (
                      <>
                        <Typography
                          style={{ marginBottom: 8, fontSize: '11pt' }}
                          variant="subtitle1"
                        >
                          {dp.trait.description}
                        </Typography>
                        <Typography
                          style={{ fontSize: '10pt', fontStyle: 'italic' }}
                          variant="subtitle2"
                        >
                          {dp.trait.verse}
                        </Typography>
                      </>
                    )}
                    {idx !== 0 && idx !== 1 && (
                      <Typography>
                        [{dp.total}] - {dp.type.charAt(0).toUpperCase()}
                      </Typography>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
            <Divider style={{ marginTop: 32 }} />
            <Typography
              align="center"
              variant="h4"
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              Spiritual Gifts
            </Typography>
            {questionResults.spiritualGifts.map((sg) => (
              <React.Fragment key={sg.key}>
                <Typography variant="h6">
                  {limit ? sg.title : `[${sg.total}] - ${sg.title}`}
                </Typography>
                <Typography variant="subtitle1">{sg.description}</Typography>
                <Typography
                  style={{
                    fontSize: '10pt',
                    fontStyle: 'italic',
                    marginBottom: 16,
                  }}
                >
                  {sg.verses}
                </Typography>
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Assessment;
