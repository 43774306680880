const hasStorage = typeof localStorage !== 'undefined';

export function setItem(key: string, value: unknown) {
  if (!hasStorage) {
    console.warn('Unable to store results.');
    return;
  }

  const json = JSON.stringify(value);

  localStorage.setItem(key, json);
}

export function getItem(key: string): unknown {
  if (!hasStorage) {
    console.warn('Unable to store results.');
    return;
  }

  const value = localStorage.getItem(key) ?? '';

  return JSON.parse(value);
}
