import discQuestions, { DISCAnswerDetail, DiscQuestion } from './discQuestions';
import spiritualGiftQuestions, {
  AnswerDetail,
  SGResult,
} from './spiritualGiftQuestions';

import { discDetail, discResult } from './discResult';

import random from '../../utils/random';
import { setItem } from '../../utils/storage';

export interface AnswerResult {
  text: string;
  value: number;
  colour: string;
}

export interface QuestionResult {
  total: number;
  questionNumber: number;
  question: string;
  answers: AnswerResult[];
}

export interface QuestionResults {
  spiritualGifts: AnswerDetail[];
  discProfile: DISCAnswerDetail[];
}

export interface IQuestionnaireManager {
  getNextQuestion: () => QuestionResult;
  isDone: () => boolean;
  getResults: (limit?: boolean) => QuestionResults;
  answerQuestion: (question: string, value: number) => void;
}

export default class QuestionnaireManager implements IQuestionnaireManager {
  discQuestions: DiscQuestion[] = [];
  spiritualGiftQuestions: Record<number, SGResult> = {};

  spiritualGiftAnswerDetails: AnswerDetail[] = [];
  discAnswerDetails: DISCAnswerDetail[] = [];

  constructor() {
    this.discQuestions = [...discQuestions.questions];
    this.spiritualGiftQuestions = { ...spiritualGiftQuestions.questions };

    this.spiritualGiftAnswerDetails = [...spiritualGiftQuestions.answerDetails];
    this.discAnswerDetails = [...discQuestions.answerDetails];
  }

  discLambda = (askedQuestion: string) => (question: string) =>
    question === askedQuestion;

  isDiscQuestion(question: string) {
    return this.discQuestions.some((d) => d.question === question);
  }

  isSpiritualGiftQuestion(question: string) {
    return Object.keys(this.spiritualGiftQuestions).find(
      (key) => this.spiritualGiftQuestions[key].question === question
    );
  }

  updateDiscResult(question: string, value: number) {
    const discQuestions = [...this.discQuestions];

    const idx = discQuestions.findIndex((q) => q.question === question);

    const result = { ...this.discQuestions[idx], answer: value };

    discQuestions.splice(idx, 1, result);

    this.discQuestions = [...discQuestions];
  }

  updateSpiritualGiftResult(key: string, question: string, value: number) {
    this.spiritualGiftQuestions = {
      ...this.spiritualGiftQuestions,
      [key]: {
        question,
        answer: value,
      },
    };
  }

  answerQuestion(question: string, value: number) {
    const discKey = this.isDiscQuestion(question);
    const sgKey = this.isSpiritualGiftQuestion(question);

    if (discKey) this.updateDiscResult(question, value);
    if (sgKey) this.updateSpiritualGiftResult(sgKey, question, value);
  }

  getNextQuestion() {
    const unansweredDisc = this.discQuestions.filter((d) => d.answer === 0);

    const unansweredSpiritualGifts = Object.keys(
      this.spiritualGiftQuestions
    ).filter((key) => this.spiritualGiftQuestions[key].answer === 0);

    const total =
      this.discQuestions.length +
      Object.keys(this.spiritualGiftQuestions).length;

    const currentQuestion =
      total - (unansweredDisc.length + unansweredSpiritualGifts.length) + 1;

    let question = null;
    let answers: AnswerResult[] = [...discQuestions.answers];

    const qIdx = currentQuestion - 1;

    if (currentQuestion <= this.discQuestions.length) {
      question = this.discQuestions[qIdx].question;
    } else {
      const key = Object.keys(this.spiritualGiftQuestions).at(qIdx - 20);

      question = this.spiritualGiftQuestions[key].question;
    }

    // const category = random.range(0, 2);

    // if (category === 0 && unansweredDisc.length > 0) {
    //   // get disc question

    //   const q = random.range(0, unansweredDisc.length);

    //   const discQuestion = unansweredDisc[q];

    //   question = discQuestion.question;
    //   answers = [...discQuestions.answers];
    // } else {
    //   const q = random.range(0, unansweredSpiritualGifts.length);

    //   //console.log(unansweredSpiritualGifts, q);

    //   question = this.spiritualGiftQuestions[unansweredSpiritualGifts[q]]
    //     .question;

    //   answers = [...spiritualGiftQuestions.answers];
    // }

    return {
      answers,
      question,
      questionNumber: currentQuestion,
      total,
    } as QuestionResult;
  }

  isDone() {
    const answeredDP = this.discQuestions.every((d) => d.answer !== 0);

    const answeredSG = Object.keys(this.spiritualGiftQuestions).every(
      (key) => this.spiritualGiftQuestions[key].answer !== 0
    );

    return answeredDP && answeredSG;
  }

  calculateSpiritualGiftResults(limit: boolean) {
    this.spiritualGiftAnswerDetails.forEach((d) => {
      const total = d.mapping.reduce((acc, qNo) => {
        const q = this.spiritualGiftQuestions[qNo];

        return acc + q.answer;
      }, 0);

      d.total = total;
    });

    let ordered = this.spiritualGiftAnswerDetails
      .map((s) => s)
      .sort((a, b) => b.total - a.total);

    if (limit) ordered = ordered.slice(0, 5);

    return ordered;
  }

  calculateDISCResults(limit: boolean) {
    this.discAnswerDetails.forEach((d) => {
      const total = this.discQuestions
        .filter((dq) => dq.type === d.type)
        .reduce((acc, dq) => {
          return acc + dq.answer;
        }, 0);

      d.total = total;
    });

    const ordered = this.discAnswerDetails
      .map((d) => d)
      .sort((a, b) => b.total - a.total);

    const [first, second, ...rest] = ordered;

    const dominant = first.type.charAt(0).toUpperCase();
    const secondary = `${dominant}/${second.type.charAt(0).toUpperCase()}`;

    if (!limit)
      return [
        { ...first, trait: discQuestions.traits[dominant] },
        { ...second, trait: discQuestions.traits[secondary] },
        ...rest,
      ];

    return [
      { ...first, trait: discQuestions.traits[dominant] },
      { ...second, trait: discQuestions.traits[secondary] },
    ];
  }

  getResults(limit = true) {
    const spiritualGifts = this.calculateSpiritualGiftResults(limit);
    const discProfile = this.calculateDISCResults(limit);

    setItem('questionnaire-results', { discProfile, spiritualGifts });

    return {
      discProfile,
      spiritualGifts,
    };
  }
}
