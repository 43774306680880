import { AnswerResult } from './questionnaireManager';

export interface DiscQuestion {
  question: string;
  answer: number;
  type: string;
}

// DISC Profile, has 4 options (Never:1, Rarely:2, Always:3, Often:4)

const answers: AnswerResult[] = [
  {
    text: 'Never',
    value: 1,
    colour: '#EBCF37',
  },
  {
    text: 'Rarely',
    value: 2,
    colour: '#7DB474',
  },
  {
    text: 'Sometimes',
    value: 3,
    colour: '#74b9ff',
  },
  {
    text: 'Often',
    value: 4,
    colour: '#a29bfe',
  },
  {
    text: 'Always',
    value: 5,
    colour: '#ff7675',
  },
];

const questions: DiscQuestion[] = [
  {
    question: 'I am assertive, demanding, and decisive.',
    answer: 0,
    type: 'dominant',
  },
  {
    question: 'I enjoy doing multiple tasks at once.',
    answer: 0,
    type: 'dominant',
  },
  {
    question: 'I thrive in a challenge-based environment.',
    answer: 0,
    type: 'dominant',
  },
  {
    question: 'I think about tasks more than others or myself.',
    answer: 0,
    type: 'dominant',
  },
  {
    question: 'I am motivated by accomplishment and authority.',
    answer: 0,
    type: 'dominant',
  },
  {
    question: 'I enjoy influencing and inspiring other people.',
    answer: 0,
    type: 'influential',
  },
  { question: 'I am optimistic about others.', answer: 0, type: 'influential' },
  {
    question: 'I tend to be the life of the party.',
    answer: 0,
    type: 'influential',
  },
  {
    question: 'I think about motivating people.',
    answer: 0,
    type: 'influential',
  },
  {
    question: 'I am motivated by recognition and approval.',
    answer: 0,
    type: 'influential',
  },
  {
    question: 'I thrive in consistent environments.',
    answer: 0,
    type: 'steady',
  },
  {
    question: 'I prefer specifics over generalizations.',
    answer: 0,
    type: 'steady',
  },
  { question: 'I enjoy small groups of people.', answer: 0, type: 'steady' },
  { question: 'I prefer being a member of a team.', answer: 0, type: 'steady' },
  {
    question: 'I am motivated by stability and support.',
    answer: 0,
    type: 'steady',
  },
  {
    question: 'I typically do not take big risks.',
    answer: 0,
    type: 'compliant',
  },
  {
    question: 'I love tasks, order, and details.',
    answer: 0,
    type: 'compliant',
  },
  { question: 'I am right most of the time.', answer: 0, type: 'compliant' },
  {
    question: 'I comply with clearly defined rules.',
    answer: 0,
    type: 'compliant',
  },
  {
    question: 'I am motivated by quality and correctness.',
    answer: 0,
    type: 'compliant',
  },
];

export interface DISCAnswerDetail {
  type: 'dominant' | 'influential' | 'steady' | 'compliant';
  description: string;
  points: string[];
  total: number;
  trait?: TraitDescription;
}

interface TraitDescription {
  description: string;
  verse?: string;
}

const traits: Record<string, TraitDescription> = {
  D: {
    description: `We are direct and decisive. We are risk takers and problem solvers.
    We are more concerned with completing tasks and winning than
    we are with gaining approval from people. Though the internal drive
    tends to make us insensitive to those around us, “D”s are not afraid
    to challenge the status quo, and we thrive when it comes to developing
    new things. We need discipline to excel, and respond to direct
    confrontation. Our greatest fear is to be taken advantage of, and
    even despite our possible weaknesses—which include an aversion to
    routine, a tendency to overstep authority, an argumentative nature,
    and a habit of taking on too much—we place a high value on time
    and use our innovative thinking to accomplish difficult tasks and
    conquer challenges.`,
  },
  'D/I': {
    description: `We are curious concluders who place emphasis on the bottom line
  and work hard to reach our goals. We are more determined than
  we are inspirational, yet our high expectations and standards for
  ourselves and those around us typically cause us to make quite an
  impact, motivating others to follow us. We have an array of interests
  and can become distracted by taking on too many projects. We often
  need to focus, prioritize, and simply slow down. Because we thrive on
  activity and forward motion, we like to accomplish tasks through a
  large number of people.`,
    verse:
      'Joshua (Joshua 1), Noah (Genesis 6-9), Sarah (Genesis 16, 1 Peter 3:6)',
  },
  'D/S': {
    description: `We are achievers with an ability to persevere. We are more active
  than passive, but possess a kind of calm sensitivity and steadiness
  that makes us good leaders. We seem to be people-oriented but can
  easily be dominant and decisive when it comes to tasks and project
  planning. We strive to accomplish goals with fierce determination
  that comes from strong internal drive, but we could benefit from
  contemplative and conservative thinking as well as spending more
  time focusing on relationships.`,
    verse:
      'Daniel (Daniel 1-6), Job (Job 1:5, James 5:11), Martha (Luke 10:38-42)',
  },
  'D/C': {
    description: `We are challengers that can either be determined students or defiant
  critics. Being in charge is important to us, yet we care little about
  what others think as long as we get the job done. We have a great
  deal of foresight and examine every avenue to find the best solution.
  We prefer to work alone. Though we fear failure and the lack of
  influence, we are motivated by challenges and can often be excellent
  administrators. We can benefit from learning to relax and paying
  more attention to people.`,
    verse: 'Malachi (Malachi 4), Nathan (2 Samuel 12:1-13), Nahum (Nahum 1-3)',
  },
  I: {
    description: `We are inspiring and impressive. Enthusiastic, optimistic, impulsive,
  and emotional—we tend to be creative problem solvers and excellent
  encouragers. We often have a large number of friends, but we can
  become more concerned with approval and popularity than
  with getting results. Our greatest fear is rejection, but we thrive when
  it comes to motivating others. Our positive sense of humor helps us
  negotiate conflicts. Though we can be inattentive to details and
  poor listeners, we can be great peacemakers and effective
  teammates when we control our feelings and minimize our urge to
  entertain and be the center of attention. We value lots of human
  touch and connection.`,
  },
  'I/D': {
    description: `We are persuaders who are outgoing and energetic. We enjoy large
  groups and use our power of influence to attain respect and convince
  people to follow our lead. Sometimes we can be viewed as fidgety
  and nervous, but it comes from our need to be a part of challenges
  that have variety, freedom, and mobility. We could benefit from
  learning to look before we leap and spending more time being
  studious and still. We make inspiring leaders and know how to get
  results from and through people.`,
    verse:
      'John the Baptist (Luke 3), Peter (Matthew 16 and 26, Acts 3), Rebekah (Genesis 24)',
  },
  'I/S': {
    description: `We are influential counselors who love people, and it’s no surprise
  that people love us. We live to please and serve, and tend to be good
  listeners. Looking good and encouraging others is important to us,
  as is following through and being obedient. We often lack in the area
  of organization and can be more concerned with the people involved
  than we are with the task at hand. However, we can be center stage
  or behind the scenes with equal effectiveness, and we shine when it
  comes to influencing and helping others.`,
    verse:
      'Barnabas (Acts 4, 9, 11-15), Elisha (1 Kings 19, 2 Kings 2-3), Nicodemus (John 3, 7, 19)',
  },
  'I/C': {
    description: `We are inspiring yet cautious assessors who are excellent
  communicators through the combination of concerned awareness
  and appreciation of people. We excel in determining ways to improve
  production. We tend to be impatient and critical, and can also be
  overly persuasive and too consumed by the desire to win. We like to
  work inside the box, and we could benefit from trying new things and
  caring less about what others think. This personality type often
  possesses a gift for teaching; we are generally dependable when it
  comes to paying attention to details and getting the job done.`,
    verse:
      'Miriam (Exodus 15-21), Ezra (Ezra 7-8), Shunammite Woman (2 Kings 4:8-37)',
  },
  S: {
    description: `We are steady and more reserved. We do not like change, and thrive in secure, nonthreatening environments. We are often friendly and understanding as well as good
  listeners and loyal workers who are happy doing the same job consistently. With an
  incredible ability to forgive, reliable and dependable “S”s tend to make the best
  friends. Our greatest fear, however, is loss of security, and our possible weaknesses
  naturally include not only resistance to change, but also difficulty adjusting to it. We
  can also be too sensitive to criticism and unable to establish priorities. In order to
  avoid being taken advantage of, we need to be stronger and learn how to say “no.”
  We also like to avoid the limelight, but when given an opportunity to genuinely help
  others, we will gladly rise to the occasion. We feel most valued when we have truly
  helped someone.`,
  },
  'S/D': {
    description: `We are quiet leaders who can be counted on to get the job done. We
  perform better in small groups and do not enjoy speaking in front of
  crowds. Though we can be soft- and hard-hearted at the same time,
  we enjoy close relationships with people, being careful not to
  dominate them. Challenges motivate us, especially ones that allow
  them to take a systematic approach. We tend to be determined,
  persevering through time and struggles. We benefit from
  encouragement and positive relationships.`,
    verse: 'Martha (Luke 10:38-42), Job (Job 1:5, James 5:11)',
  },
  'S/I': {
    description: `We are inspirational counselors who exhibit warmth and sensitivity.
  Tolerant and forgiving, we have many friends because they accept
  and represent others well. Our social nature and desire to be likable
  and flexible makes us inclined to be overly tolerant and non-confrontational.
  We will benefit from being more task-oriented and paying
  more attention to detail. Kind and considerate, we include others and
  inspire people to follow us. Words of affirmation go a long way with
  us, and with the right motivation, we can be excellent team players.`,
    verse:
      'Mary Magdalene (Luke 7:36-47), Barnabas (Acts 4, 9, 11-15), Elisha (1 Kings 19, 2 Kings 2-13)',
  },
  'S/C': {
    description: `We are diplomatic and steady, as well as detail-oriented. Stable and
  contemplative, we like to weigh the evidence and discover the facts
  to come to a logical conclusion. More deliberate, we prefer to take
  our time, especially when the decision involves others. Possible
  weaknesses include being highly sensitive and unable to handle
  criticism, and we also need to be aware of the way we treat others.
  Operating best in precise and cause-worthy projects, we can be a
  peacemaker; this makes us a loyal team member and friend.`,
    verse:
      'Moses (Exodus 3, 4, 20, 32), John (John 19:26-27), Eliezer (Genesis 24)',
  },
  C: {
    description: `We are compliant and analytical. Careful and logical lines of thinking drive us
  forward, and accuracy is a top priority. We hold high standards and value systematic
  approaches to problem solving.
  Though we thrive when given opportunities to find solutions, we tend
  to ignore the feelings of others and can often be critical and
  downright crabby. Verbalizing feelings is difficult for us, but when
  we are not bogged down in details and have clear-cut boundaries,
  we can be big assets to the team by providing calculated “reality
  checks.” Our biggest fear is criticism, and our need for perfection is
  often a weakness, as is our tendency to give in when in the midst of
  an argument. However, we are thorough in all activities and can bring
  a conscientious, even-tempered element to the team that will provide solid
  grounding. We value being correct.`,
  },
  'C/I': {
    description: `We are attentive to the details. We tend to impress others by doing
  things right and stabilizing situations. Not considered aggressive or
  pushy, we enjoy both large and small crowds. Though we work well
  with people, we are sometimes too sensitive to what others think
  about us and our work. We could benefit from being more assertive
  and self-motivated. Often excellent judges of character, we easily
  trust those who meet our standards. We are moved by genuine and
  enthusiastic approval as well as concise and logical explanations.`,
    verse: 'Miriam (Exodus 15-21, Numbers 12:1-15), Ezra (Ezra 7, 8)',
  },
  'C/S': {
    description: `We are systematic and stable. We tend to do one thing at a time—
  and do it right. Reserved and cautious, we would rather work behind
  the scenes to stay on track; however, we seldom take risks or try new
  things and naturally dislike sudden changes in our environments.
  Precisionists to the letter, we painstakingly require accuracy and fear
  criticism, which we equate to failure. Diligent workers, our motivation
  comes from serving others.`,
    verse: 'Esther (Esther 4), Zechariah (Luke 1), Joseph (Matthew 1:1-23)',
  },
  'C/D': {
    description: `We are cautious and determined designers who are consistently
  task-oriented and very aware of problems. Sometimes viewed as
  insensitive, we do care about individual people but have a difficult
  time showing it. We often feel we are the only ones who can do the
  job the way it needs to be done, but because of our administrative
  skills, we are able to bring plans for change and improvements to
  fruition. We have a tendency to be serious and could benefit from
  being more optimistic and enthusiastic. Despite our natural drive to
  achieve, we should concentrate on developing healthy relationships
  and simply loving people.`,
    verse:
      'Bezalel (Exodus 35:30-36, 8, 37:1-9), Jochebed (Exodus 1:22-2:4), Jethro (Exodus 2,18)',
  },
};

const answerDetails: DISCAnswerDetail[] = [
  {
    type: 'dominant',
    description:
      '“D” personalities are dominant, direct, task-oriented, decisive, organized, outgoing, and outspoken. As you embrace these strengths, also make sure to:',
    points: [
      'Listen attentively to others.',
      'Support other team members.',
      'Invest in personal relationships.',
      'Balance controlling and domineering tendencies.',
      'Value the opinions, feelings, and desires of others.',
    ],
    total: 0,
  },
  {
    type: 'influential',
    description:
      '“I” personalities are influential, witty, easygoing, outgoing, and people-oriented. As you embrace these strengths, also make sure to:',
    points: [
      'Be aware of tasks that need to be accomplished.',
      'Balance your emotions, words, and actions.',
      'Remember to consider details and facts.',
      'Slow down your pace for others when necessary.',
      'Listen attentively to others instead of only talking.',
      'Choose thoughtful decision-making over impulsive decision-making.',
    ],
    total: 0,
  },
  {
    type: 'steady',
    description:
      '“S” personalities are steady, stable, analytical, introverted, and people-oriented. As you embrace these strengths, also make sure to:',
    points: [
      'Take initiative.',
      'Practice flexibility.',
      'Approach confrontation constructively.',
      'Be direct in your interactions when necessary.',
      'Realize change can be healthy, and be willing to adapt.',
      'Consider the overall goals of your family or group, not just specific processes or procedures.',
    ],
    total: 0,
  },
  {
    type: 'compliant',
    description:
      '“C” personalities are compliant, competent, task-oriented, goal-oriented, and introverted. As you embrace these strengths, also make sure to:',
    points: [
      'Be decisive when necessary.',
      'Cultivate personal relationships.',
      'Be open to others’ ideas and methods.',
      'Balance your focus between facts and people.',
      'Focus on doing the right things, not just doing things right.',
      'Help others accomplish their goals.',
    ],
    total: 0,
  },
];

export default {
  answers,
  questions,
  answerDetails,
  traits,
};
